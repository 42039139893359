@tailwind base;
@tailwind components;
@tailwind utilities;

/* custom tailwind classes */

.container {
  @apply bg-black;
  @apply text-white;
  @apply min-h-screen;
  @apply min-w-full;
}
.col-center {
  @apply flex;
  @apply flex-col;
  @apply items-center;
}
.row-center {
  @apply flex;
  @apply flex-row;
  @apply items-center;
}

/* custom css */
.fp-tableCell {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  min-height: 100vh;
  text-align: center;
}

body {
  background: black;
  color: white;
  font-family: "Source Code Pro", monospace;
}
h1,
h2,
h3,
h4,
h5 {
  font-family: "Oswald", sans-serif;
  text-transform: capitalize;
}
.blue-underline {
  background-image: linear-gradient(120deg, #5cffe4, #5cffe4 100%);
  background-repeat: no-repeat;
  background-size: 100% 0.3em;
  background-position: 0 80%;
  transition: background-size 0.25s ease-in;
  -webkit-text-stroke: 1px black;
  width: max-content;
}

.blue-underline:hover {
  background-size: 100% 88% !important;
}
.green-underline {
  background-image: linear-gradient(120deg, #a9ff03, #a9ff03 100%);
  background-repeat: no-repeat;
  background-size: 100% 0.3em;
  background-position: 0 80%;
  transition: background-size 0.25s ease-in;
  -webkit-text-stroke: 1px black;
  width: max-content;
}

.green-underline:hover {
  background-size: 100% 88% !important;
}
.no-stroke {
  -webkit-text-stroke: 0px black !important;
}
.button {
  font-weight: bolder;
  background: #c58fff;
  text-align: center;
  font-weight: bolder;
  color: black;
  padding: 15px;
  padding-left: 30px;
  padding-right: 30px;
  box-shadow: -3px 3px 0 #39ff14;
  border: 5px solid black;
  border-right: 0px;
  border-top: 0px;
}
.button:hover {
  background-color: #5cffe4;
}
#svg-button:hover {
  fill: #5cffe4;
}
/* scrollbar styling */

div::-webkit-scrollbar-track {
  background-color: black;
}

div::-webkit-scrollbar {
  width: 5px;
  background-color: black;
}

div::-webkit-scrollbar-thumb {
  background-color: #c58fff;
}
